
import React from 'react'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'

import { Bars3Icon } from '@heroicons/react/24/outline'
import langIcon from '../assets/images/mkhHeader/lang.png'
import setupIcon from '../assets/images/mkhHeader/setup.png'

import logoIcon from '../assets/images/header/logoTitle.png'
import LangModal from './LangModal'
import Setup from './Setup/Setup'
import { show as setupShow } from '../store/modules/setupStore'
import { show as langModalShow } from '../store/modules/langModalStore'
import ScrollingText from '../components/ScrollingText'
import { getHomeNoticeApi } from '../request/api'
import { Modal } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
function Header () {
  const nav = useNavigate()
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => { }, [])
  useEffect(() => {
    // if (!localStorage.getItem('language')) {
    //   localStorage.setItem('language', 'zh_TW')
    // } else {
    //   if (localStorage.getItem('language') == 'zh_CN') {
    //     i18n.changeLanguage('zh_CN')
    //   } else if (localStorage.getItem('language') == 'en_US') {
    //     i18n.changeLanguage('en_US')
    //   } else if (localStorage.getItem('language') == 'zh_TW') {
    //     i18n.changeLanguage('zh_TW')
    //   } else if (localStorage.getItem('language') == 'ja') {
    //     i18n.changeLanguage('ja')
    //   } else if (localStorage.getItem('language') == 'ko') {
    //     i18n.changeLanguage('ko')
    //   } else if (localStorage.getItem('language') == 'es') {
    //     i18n.changeLanguage('es')
    //   } else if (localStorage.getItem('language') == 'pt') {
    //     i18n.changeLanguage('pt')
    //   } else if (localStorage.getItem('language') == 'de') {
    //     i18n.changeLanguage('de')
    //   } else if (localStorage.getItem('language') == 'fr') {
    //     i18n.changeLanguage('fr')
    //   } else if (localStorage.getItem('language') == 'th') {
    //     i18n.changeLanguage('th')
    //   }
    // }
  }, [])
  const [noticeVisible1, setNoticeVisible1] = useState(false) //打开滑块
  const [homeNotice, setHomeNotice] = useState()
  const getHomeNotice = async () => {
    try {
      const res = await getHomeNoticeApi()
      if (res.data.code == 200) {
        setHomeNotice(res.data.data)
        if (res.data.data.is_bomb == 1) {
          setNoticeVisible1(true)
        }
      } else {
      }
    } catch (error) { }
  }
  useEffect(() => {
    getHomeNotice()
  }, [])

  return (
    <div className=" relative flex justify-between w-screen h-[43px] items-center  z-2 bg-[#FFD700]">
      <div className=" ml-4 flex justify-end">
        <Setup />
        <div
          className="w-9 h-9 flex justify-center items-center "
          onClick={() => {
            dispatch(setupShow())
          }}>
          <img src={setupIcon} alt="" className="w-7 h-7  mr-3" />
          {/* <Bars3Icon className="text-[#000] w-9 h-9"></Bars3Icon> */}
        </div>
      </div>
      {/*logo */}
      <div className="flex-auto   flex justify-center items-center">
        <img src={'/img/header/logo.png'} className="w-[30px] h-[30px]" />
        <div className='  font-black text-[20px] text-black ml-2'>AICC</div>
        <LangModal></LangModal>
      </div>
      <Modal
        visible={noticeVisible1}
        content={
          <div className="flex flex-col items-center text-black">
            <div className="font-bold mb-2">{homeNotice?.title}</div>
            <div>{homeNotice?.text}</div>
            <div className="flex justify-end w-full">
              {homeNotice?.updated_at}
            </div>
          </div>
        }
        bodyStyle={{
          background: '#fff',
          // color: '#fff',
        }}
        // showCloseButton
        onClose={() => setNoticeVisible1(false)}
        closeOnMaskClick="true"
      // closeOnMaskClick={true}
      ></Modal>
      <div
        className="mr-2 w-[calc(100%-236px)] flex items-center text-black"
        onClick={() => {
          setNoticeVisible1(true)
          // Modal.show({
          //   visible: noticeVisible1,
          //   content: (
          //     <div className="flex flex-col items-center text-white">
          //       <div className="font-bold mb-2">{homeNotice?.title}</div>
          //       <div>{homeNotice?.text}</div>
          //       <div className="flex justify-end w-full">
          //         {homeNotice?.updated_at}
          //       </div>
          //     </div>
          //   ),
          //   bodyStyle: {
          //     background: '#231C52',
          //     // color: '#fff',
          //   },
          //   closeOnMaskClick: true,
          // })
        }}>
        <ScrollingText text={homeNotice?.text}></ScrollingText>
      </div>
      <div className="mr-2">
        <div
          className=" h-[25px] px-1 flex justify-center items-center rounded-md"
          onClick={() => {
            // dispatch(langModalShow())
            // onFalse()
            nav('/noticeList')
          }}>
          <img
            className="w-[20px] h-[20px]"
            src={'/img/header/la.svg'}
            alt=""
          />
        </div>
      </div>
      <div
        className="  flex justify-end"
        onClick={() => {
          dispatch(langModalShow())
        }}>
        <img src={langIcon} alt="" className="w-7 h-7  mr-3" />
      </div>

    </div>
  )
}

export default Header
