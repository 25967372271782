import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { show, showClose } from '../store/modules/langModalStore'
import { setNewLang } from '../store/modules/langChangeStore'
import { getLang, setLang } from '../utils/token'
import './LangModal.scss'
import { changeLanguage } from 'i18next'
import { useLocation } from 'react-router-dom'
import { SaveInfo as NoticeSaveInfo } from '../store/modules/homeNoticeStore'
import { getHomeNoticeApi } from '../request/api'
export default function LangModal () {
  const { t } = useTranslation()
  const location = useLocation()
  //修改语言弹窗的Store
  const langModalStore = useSelector((state) => state.langModalStore.show)
  const dispatch = useDispatch()
  const [isOpenExport, setIsOpenExport] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectLang, setSelectLang] = useState(getLang() || 'zh_TW')
  //open or close modal
  const toggleExportModal = () => {
    console.log('toggleExportModal')
    const action = show()
    dispatch(action)
  }
  const confirm = () => {
    changeLanguage(selectLang)
    // console.log('selectLang', selectLang)
    dispatch(setNewLang(selectLang))
    setLang(selectLang)
    dispatch(showClose())
    console.log('location.pathname', location.pathname)
    if (location.pathname === '/aiarbitrage') {
      // window.location.reload()
    }
  }
  // useEffect(() => {
  //   confirm()
  // }, [selectLang])
  //修改语言后调用首页公告接口
  const getHomeNotice = async () => {
    try {
      const res = await getHomeNoticeApi({})
      console.log('res', res)
      if (res.data.code === 200) {
        dispatch(NoticeSaveInfo(res.data.data))
        // setNotice(res.data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    // console.log('langModalStore', langModalStore)
    setIsOpenExport(langModalStore)
  }, [langModalStore])
  return (
    <div>
      {/* 充值 */}
      {isOpenExport && (
        <>
          {/* 背景遮罩 */}
          {/* <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-10"
            onClick={toggleExportModal}
          ></div> */}

          {/* Modal */}
          <div
            className={`fixed top-0 bottom-0 right-0 left-0 z-20 flex items-center justify-center `}>
            <div
              className="fixed top-0 bottom-0 right-0 left-0 bg-[#FCFCFD]  backdrop-blur-xl z-20"
              onClick={toggleExportModal}></div>
            <div
              className={` bg-transparent text-[#323142] pt-5 pb-4 px-4 w-11/12 rounded-lg  z-20 transform  ${isOpenExport ? 'modal_open_Effect' : ''
                } ease-in-out  duration-300 `}>
              {/* Modal 内容 */}
              <div>
                <div className="flex justify-center items-center">
                  <span className="text-[1.625rem] font-bold">
                    {t('language')}
                  </span>
                </div>
                <div className="mt-[0.5rem] mb-[0.5rem] flex justify-center items-center">
                  <span className="text-[1rem]">
                    {t('SelectAndConfirmYourLanguage')}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <div className="w-[21.5rem]  border  rounded-lg shadow-xl">
                    <div
                      className={`rounded-t-lg border  h-[3rem] flex justify-center items-center ${selectLang === 'zh_TW' ? 'bg-[#FFD700]  text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectLang('zh_TW')
                        changeLanguage('zh_TW')
                        // console.log('selectLang', selectLang)
                        dispatch(setNewLang('zh_TW'))
                        setLang('zh_TW')
                        dispatch(showClose())
                        getHomeNotice()
                        console.log('location.pathname', location.pathname)
                        // if (location.pathname === '/aiarbitrage') {
                        //   window.location.reload()
                        // } 
                      }}>
                      繁體中文
                    </div>
                    <div
                      className={`border  h-[3rem] flex justify-center items-center ${selectLang === 'en_US' ? 'bg-[#FFD700] text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectLang('en_US')
                        changeLanguage('en_US')
                        // console.log('selectLang', selectLang)
                        dispatch(setNewLang('en_US'))
                        setLang('en_US')
                        dispatch(showClose())
                        getHomeNotice()
                        console.log('location.pathname', location.pathname)
                        // if (location.pathname === '/aiarbitrage') {
                        //   window.location.reload()
                        // }
                      }}>
                      English
                    </div>
                    <div
                      className={`border  h-[3rem] flex justify-center items-center ${selectLang === 'pl' ? 'bg-[#FFD700] text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectLang('pl')
                        changeLanguage('pl')
                        // console.log('selectLang', selectLang)
                        dispatch(setNewLang('pl'))
                        setLang('pl')
                        dispatch(showClose())
                        getHomeNotice()
                        console.log('location.pathname', location.pathname)
                        // if (location.pathname === '/aiarbitrage') {
                        //   window.location.reload()
                        // }
                      }}>
                      Polski
                    </div>

                    <div
                      className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${selectLang === 'zh_CN' ? 'bg-[#FFD700] text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectLang('zh_CN')
                        changeLanguage('zh_CN')
                        // console.log('selectLang', selectLang)
                        dispatch(setNewLang('zh_CN'))
                        setLang('zh_CN')
                        dispatch(showClose())
                        getHomeNotice()
                        console.log('location.pathname', location.pathname)
                        // if (location.pathname === '/aiarbitrage') {
                        //   window.location.reload()
                        // }
                      }}>
                      简体中文
                    </div>
                  </div>
                </div>
                {/* <div className='mt-[1rem]'>
                  <div><button className='btn text-[1rem] w-[19.5rem] h-[2.625rem] rounded-3xl border-none bg-[#0091AF] hover:bg-[#0091AF] hover:border-none'
                    onClick={confirm}
                  >{t('Confirm')}</button></div>
                  <div className='mt-[1rem]'>
                    <button className='btn text-[1rem] text-[#323142] w-[19.5rem] h-[2.625rem] rounded-3xl border  border-[#0091AF] bg-transparent hover:bg-transparent'
                      onClick={toggleExportModal}
                    >{t('Cancel')}</button></div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
