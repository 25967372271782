import React, { useRef, useEffect, useState } from 'react'
import { motion } from 'framer-motion'

const SeamlessScrollingText = ({ text }) => {
  const containerRef = useRef(null)
  const textRef = useRef(null)
  const [shouldScroll, setShouldScroll] = useState(false)

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth
    const textWidth = textRef.current.offsetWidth
    setShouldScroll(textWidth > containerWidth) // 如果文本宽度大于容器宽度，则滚动
  }, [text]) // 依赖项是text，因为文本变化可能影响是否需要滚动

  const duration = Math.max(10, text?.length * 0.4)

  const scrollVariants = {
    animate: {
      x: shouldScroll ? [0, -100 + '%'] : 0, // 如果不需要滚动，就不移动
      transition: shouldScroll
        ? {
            repeat: Infinity,
            repeatType: 'loop',
            duration: duration,
            ease: 'linear',
          }
        : {},
    },
  }

  return (
    <div
      ref={containerRef}
      style={{ overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex' }}>
      <motion.div
        ref={textRef} // 应用ref来测量文本宽度
        style={{ paddingRight: shouldScroll ? '100%' : '0%' }} // 如果需要滚动，则设置右填充
        variants={scrollVariants}
        animate="animate">
        {text}
      </motion.div>
      {shouldScroll && ( // 如果需要滚动，渲染第二个文本元素
        <motion.div
          style={{ paddingRight: '100%' }}
          variants={scrollVariants}
          animate="animate">
          {text}
        </motion.div>
      )}
    </div>
  )
}

export default SeamlessScrollingText
